import React, { FC } from 'react';
import styles from './index.module.scss';

// 3rd party libraries
import classnames from 'classnames';
import {
  Row,
  Col,
  CardDeck,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import Link from 'next/link';

// Components
import DateTime from '../../../components/Cards/CardsDateTime/CardsDateTime';
import CardsImage from '../../Cards/CardsImage/CardsImage';

// Libraries
import sessionsCount from '../../../libs/sessionsCount';
import sanitize from '@bt-react/libs/sanitize';
import GetURLParamQuery from '../../../libs/GetURLParamQuery';
import summitGridLink from '../../../libs/gridLink/summitGridLink';

interface listGrids {
  gridItemName?: string; // What are all the grid item names?@hugo? Lets change this to string literal type
  listStyle?: string; // Can we change this to string literal type as well.
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  clickableCard?: boolean;
  pageType?: string; // needed to pass into the image comp to try and format different resolutions.
  footLinkTitle: string;
  gridData: SummitsArray[];
  flexDirection?:
    | 'flex-row'
    | 'flex-reverse-row'
    | 'flex-reverse-column'
    | 'flex-column';
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  type: 'summits' | 'series' | 'virtualEvents';
  utmParams?: string;
  dataBddTitle?: string;
  dataBddDesc?: string;
  summitUrl: string;
  seriesUrl: string;
  priorityStatus?: boolean;
}

export interface SummitsArray {
  id: number;
  title: string;
  description: string;
  scheduledStartDate: Date;
  scheduledEndDate: Date;
  visibility: string;
  imageUrl: string;
  companyLogo: string;
  tracks: Track[];
  featured: boolean;
  wordPressLink: string;
}

export interface Track {
  id: number;
  name: string;
  communications: Communication[];
}

export interface Communication {
  channelId: number;
  communicationId: number;
}

const SummitsGridItems: FC<listGrids> = ({
  gridItemName,
  listStyle,
  listLayout,
  cardLayout,
  clickableCard,
  pageType,
  gridData,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  footLinkTitle,
  type,
  utmParams,
  dataBddTitle,
  dataBddDesc,
  summitUrl,
  seriesUrl,
  priorityStatus,
}) => {
  const Data = gridData;

  let siteLink, titleLength, envUrl;

  if (type === 'summits') {
    envUrl = summitUrl;
    siteLink = envUrl + '/summit/';
    titleLength = 1; // text truncation value
  } else {
    envUrl = seriesUrl;
    siteLink = envUrl + '/series/';
    titleLength = 2; // text truncation value
  }

  const getUtm = GetURLParamQuery(utmParams);

  return (
    <Row className={classnames(styles[listLayout], 'g-4')}>
      {Data?.map(
        (
          {
            id,
            title,
            description,
            imageUrl,
            tracks,
            scheduledStartDate,
            scheduledEndDate,
            wordPressLink,
          },
          index
        ) => (
          <Col
            sm={rowColSm}
            md={rowColMd}
            lg={rowColLg}
            xl={rowColXl}
            className={styles['column']}
            key={`SummitGridItemsCol-` + id}
            data-section-name={gridItemName}
          >
            <CardDeck key={`CardDeck-` + id} className={classnames('h-100')}>
              <Card
                key={`Card-` + id}
                className={classnames(
                  styles['list-item'],
                  styles[gridItemName],
                  styles[listStyle],
                  styles[cardLayout],
                  styles[type],
                  'h-100'
                )}
              >
                {clickableCard === true && (
                  <Link
                    className={classnames('stretched-link')}
                    href={summitGridLink(
                      wordPressLink,
                      id,
                      type,
                      utmParams,
                      gridItemName,
                      index + 1,
                      siteLink,
                      getUtm
                    )}
                    aria-label={'View ' + title}
                  ></Link>
                )}

                <div
                  key={`img-wrap-` + id}
                  className={classnames(styles['img-wrap'], styles[pageType])}
                >
                  <Link
                    href={summitGridLink(
                      wordPressLink,
                      id,
                      type,
                      utmParams,
                      gridItemName,
                      index + 1,
                      siteLink,
                      getUtm
                    )}
                  >
                    <CardsImage
                      key={`CardsImage-` + id}
                      id={id}
                      href={imageUrl}
                      title={title}
                      gridType={type}
                      pageType={pageType} // let comp know what page this is i.e search
                      priorityStatus={priorityStatus}
                    />
                  </Link>
                </div>

                <CardBody key={`CardBody-` + id} className={styles['cardbody']}>
                  <div key={`main-body-` + id} className={styles['main-body']}>
                    {/* For series search page we display title at the top - summits also needs title to the top */}
                    {type === 'summits' &&
                      (gridItemName == 'search-summit-item' ||
                        gridItemName === 'summit-item') && (
                        <Link
                          href={summitGridLink(
                            wordPressLink,
                            id,
                            type,
                            utmParams,
                            gridItemName,
                            index + 1,
                            siteLink,
                            getUtm
                          )}
                        >
                          <CardTitle
                            tag="h2"
                            className={styles['item-title']}
                            key={`item-title-` + id}
                            data-bdd={dataBddTitle}
                          >
                            <Truncate key={`Truncate` + id} lines={titleLength}>
                              {sanitize(title)}
                            </Truncate>
                          </CardTitle>
                        </Link>
                      )}

                    {/* Timestamp displayed top of card   for search grid or horizintal card layouts grid */}
                    {type === 'summits' &&
                      gridItemName === 'search-summit-item' && (
                        <CardText
                          key={`item-meta-` + id}
                          tag="p"
                          className={classnames(
                            styles['item-meta'],
                            styles[type]
                          )}
                        >
                          <DateTime
                            key={`DateTime-` + id}
                            dateTime={scheduledStartDate}
                            endDate={scheduledEndDate}
                            fromType="summit"
                          />
                          <span key={`sessionsCount-` + id}>
                            <span className={styles['text-divider']}>|</span>
                            {sessionsCount(tracks)} sessions
                          </span>
                        </CardText>
                      )}

                    <CardText
                      tag="p"
                      className={classnames(styles['card-description'])}
                      key={`card-description` + id}
                      data-bdd={dataBddDesc}
                    >
                      <Truncate key={`Truncate` + id} lines={2}>
                        {sanitize(description)}
                      </Truncate>
                    </CardText>

                    {/* Timestamp displayed in footer  for homepage grid or gridItemName 'summit-item */}
                    {type === 'summits' && gridItemName === 'summit-item' && (
                      <CardText
                        key={`item-meta-` + id}
                        tag="div"
                        className={classnames(
                          styles['item-meta'],
                          styles[type]
                        )}
                      >
                        <div className={styles['footchild1']}>
                          <DateTime
                            key={`DateTime-` + id}
                            dateTime={scheduledStartDate}
                            endDate={scheduledEndDate}
                            fromType="summit"
                          />
                          <span key={`sessionsCount-` + id}>
                            <span className={styles['text-divider']}>|</span>
                            {sessionsCount(tracks)} sessions
                          </span>
                        </div>

                        <div className={styles['footchild2']}>
                          {footLinkTitle != '' && (
                            <Link
                              href={summitGridLink(
                                wordPressLink,
                                id,
                                type,
                                utmParams,
                                gridItemName,
                                index + 1,
                                siteLink,
                                getUtm
                              )}
                              key={`footLinkTitle-` + id}
                              data-bdd="see-agenda-link-summits"
                            >
                              {footLinkTitle}{' '}
                              <FontAwesomeIcon
                                key={`FontAwesomeIcon-` + id}
                                icon={faAngleRight}
                                className={styles['bt-icons']}
                              ></FontAwesomeIcon>
                            </Link>
                          )}
                        </div>
                      </CardText>
                    )}
                  </div>
                </CardBody>
              </Card>
            </CardDeck>
          </Col>
        )
      )}
    </Row>
  );
};

export default SummitsGridItems;
