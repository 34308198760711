import { useRouter } from 'next/router';

/**
 *
 * Function to return the URL Params Query if it exist otherwise it will use the default UTM provided.
 * @export
 * @param {string} defaultUtm
 * @returns {string}
 */

export default function GetURLParamQuery(defaultUtm: string = '') {
    const router = useRouter();

    let formattedUTM;

    // get utm from url. split the string when it see '?', then take second part of the array.
    const utmFromUrl = router.asPath.split('?')?.[1];

    // If there is a query params from the url, then use it otherwise just use the default utm that is provided in the function params.
    if (utmFromUrl) {
      formattedUTM = '?' + utmFromUrl;
    } else {
      formattedUTM = defaultUtm;
    }

  return formattedUTM;
}
